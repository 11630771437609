<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-layout wrap>
      <v-flex md12>
        <!-- Search -->
        <material-card
          :title="$t('common.search')"
          color="green"
          flat
          full-width
          class="bg-white"
        >
          <v-form>
            <v-container py-0>
              <v-layout wrap>
                <v-flex
                  xs12
                  md3>
                  <v-daterange
                    v-model="range"
                    :presets="presets"
                    :input-props="inputProps"
                    :locale="$i18n.locale"
                    :start-label="$t('evoucher.issuance.startDate')"
                    :end-label="$t('evoucher.issuance.endDate')"
                    :menu-props="menuProps"
                    :separator-label="$t('evoucher.to')"
                    display-format="dd/MM/yyyy"
                    @input="onDateRangeChange"
                  />
                </v-flex>
                <v-flex
                  xs12
                  md3>
                  <v-text-field
                    v-model="historySearch.voucherSerial"
                    :label="$t('evoucher.voucherSerial')"
                    @keyup.enter="onSearch"
                  />
                </v-flex>
                <v-flex
                  xs12
                  md3>
                  <v-text-field
                    v-model="historySearch.customerInfo"
                    :label="$t('customer.info')"
                    @keyup.enter="onSearch"
                  />
                </v-flex>
                <v-flex
                  xs12
                  md3>
                  <v-text-field
                    v-model="historySearch.agentName"
                    :label="$t('entity.agent')"
                    @keyup.enter="onSearch"
                  />
                </v-flex>
              </v-layout>
              <v-btn
                style="margin-right: 0;"
                class="pull-right"
                color="success"
                @click="onSearch"
              >
                <span class="btn-label mr-3">
                  <i class="glyphicon glyphicon-search" />
                </span>
                <span style="text-transform: none;">{{
                  $t("common.search")
                }}</span>
              </v-btn>
            </v-container>
          </v-form>
        </material-card>
        <!-- Receive list -->
        <material-card
          :title="$t('voucherHistory.voucherUsageHistory')"
          color="green"
          flat
          full-width
        >
          <v-btn
            v-if="!isEntityDisabled()"
            color="success"
            dark
            style="float: right;"
            @click="onExportToExcelFile"
          >
            <v-icon>mdi-file-export</v-icon>
            <span style="text-transform: none;">{{ $t('voucherReport.exportExcel') }}</span>
          </v-btn>
          <v-data-table
            :no-data-text="$t('common.noDataAvailable')"
            :headers="voucherHistoryHeaders"
            :items="voucherHistoryList"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="text--darken-3 font-medium"
                v-text="$t(header.text)"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }">
              <td
                v-for="(header, index) in voucherHistoryHeaders"
                :key="header.value + index"
                :style="header.value == 'description' ? 'min-width: 150px;' : ''"
              >
                <span v-if="header.value == 'voucherSerial'">
                  <div
                    v-for="(voucherSerial, index) in item[header.value]"
                    :key="voucherSerial + index">
                    {{ voucherSerial }}
                  </div>
                  <div v-if="item.totalLengthVoucher > 5">...</div>
                </span>
                <span v-else-if="header.value == 'action'">
                  {{ $t(getActionName(item[header.value])) }}
                </span>
                <span
                  v-else-if="header.value == 'customer' || header.value == 'userPerform'"
                  class="action-item"
                  @click="viewCustomerInfo(item[header.value])">
                  <b>{{ item[header.value].fullname }}</b>
                  <span v-if="item[header.value].email"><br>({{ item[header.value].email }})</span>
                </span>
                <span
                  v-else-if="header.value == 'toEntity'"
                  class="action-item"
                  @click="viewDetailEntity(item[header.value])">
                  {{ item[header.value].name }}
                </span>
                <span
                  v-else-if="header.value == 'fromEntity'"
                  class="action-item"
                  @click="item[header.value].name !== '' ? viewDetailEntity(item[header.value].entity) : viewCustomerInfo(item[header.value].user)"
                >
                  {{ item[header.value].displayName }}
                </span>
                <span v-else>{{ item[header.value] }}</span>
              </td>
            </template>
          </v-data-table>
        </material-card>
        <pagination
          :total="paginate.totalPage"
          :current-page="paginate.currentPage"
          :row-per-page="paginate.rowPerPage"
          @onPageChange="onPageChange"
        />
      </v-flex>
    </v-layout>
    <loading-bar :is-loading="isLoading" />
    <updateCustomerInfoModal ref="updateCustomerInfo" />
    <entityDetailModal ref="entityDetailModal" />
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
// import VoucherHistoryType from 'enum/voucherHistoryType'
import dateUtils from 'utils/dateUtils'
import functionUtils from 'utils/functionUtils'
import XLSX from 'xlsx'
import { ISO_FORMAT } from 'utils/constants'
import { format, subDays } from 'date-fns'
import UpdateCustomerInfoModal from './../Customer/UpdateCustomerInfoModal'
import EntityDetailModal from 'Components/EntityDetailModal'
export default {
  components: {
    UpdateCustomerInfoModal,
    EntityDetailModal
  },
  data: () => ({
    voucherHistoryHeaders: [
      { text: 'common.nonumber', value: 'stt' },
      { text: 'voucherHistory.dateAction', value: 'dateCreated' },
      { text: 'evoucher.voucherSerial', value: 'voucherSerial' },
      { text: 'voucherHistory.implementAgencies', value: 'fromEntity' },
      { text: 'voucherHistory.receivingAgencies', value: 'toEntity' },
      { text: 'customer.customer', value: 'customer' },
      { text: 'voucherHistory.peopleAction', value: 'userPerform' },
      { text: 'voucherHistory.action', value: 'action' },
      { text: 'evoucher.issuance.description', value: 'description' }
    ],
    voucherHistoryList: [],
    paginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    },
    isLoading: false,
    range: { start: format(subDays(new Date(), 5), ISO_FORMAT), end: format(new Date(), ISO_FORMAT) },
    inputProps: { solo: false, style: { width: '240px' } },
    menuProps: { offsetY: true, closeOnContentClick: false },
    presets: [
      {
        label: 'rangeCalendar.today',
        range: [format(new Date(), ISO_FORMAT), format(new Date(), ISO_FORMAT)]
      },
      {
        label: 'rangeCalendar.yesterday',
        range: [
          format(subDays(new Date(), 1), ISO_FORMAT),
          format(subDays(new Date(), 1), ISO_FORMAT)
        ]
      },
      {
        label: 'rangeCalendar.last7Days',
        range: [
          format(subDays(new Date(), 7), ISO_FORMAT),
          format(subDays(new Date(), 1), ISO_FORMAT)
        ]
      },
      {
        label: 'rangeCalendar.lastWeek',
        range: [
          format(
            new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate() - 7
            ),
            ISO_FORMAT
          ),
          format(
            new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate()
            ),
            ISO_FORMAT
          )
        ]
      },
      {
        label: 'rangeCalendar.last30Days',
        range: [
          format(subDays(new Date(), 30), ISO_FORMAT),
          format(subDays(new Date(), 1), ISO_FORMAT)
        ]
      },
      {
        label: 'rangeCalendar.lastMonth',
        range: [
          format(
            new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
            ISO_FORMAT
          ),
          format(
            new Date(new Date().getFullYear(), new Date().getMonth(), 0),
            ISO_FORMAT
          )
        ]
      },
      {
        label: 'rangeCalendar.currentMonth',
        range: [
          format(
            new Date(new Date().getFullYear(), new Date().getMonth(), 1),
            ISO_FORMAT
          ),
          format(
            new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
            ISO_FORMAT
          )
        ]
      }
    ],
    dateArray: [],
    historySearch: {
      voucherSerial: null,
      agentName: null,
      customerInfo: null
    }
  }),
  created () {
    this.getVoucherHistoryList()
  },
  methods: {
    isEntityDisabled: function () {
      return functionUtils.isCurrentEntityLocked() || functionUtils.isCurrentEntityExpired()
    },
    /**
     * Search histories
     */
    onSearch: function () {
      this.getVoucherHistoryList()
    },
    /**
     * Date range change
     */
    onDateRangeChange: function () {
      let dateArray = []
      dateArray = dateUtils.getDaysBetweenTwoDate(this.range.start, this.range.end, 'YYYY-MM-DD')
      this.dateArray = dateArray
    },
    /**
     * Handle voucher histories data
     */
    handleVoucherHistoriesData: function (data, handleExcel, paginate) {
      let voucherHistoryList = []
      for (let i = 0, size = data.length; i < size; i++) {
        let voucherSerialCodeList = []
        let description = functionUtils.parseVoucherHistoryDescription(
          data[i].type,
          data[i].from_entity, data[i].to_entity,
          data[i].from_user, data[i].to_user,
          data[i].total_voucher,
          data[i].product_order, data[i].store_channel_data
        )

        for (let j = 0, voucherSize = (data[i].voucher_list.length >= 5 && !handleExcel ? 5 : data[i].voucher_list.length); j < voucherSize; j++) {
          if (handleExcel) {
            voucherSerialCodeList += data[i].voucher_list[j].serial + (j < data[i].voucher_list.length - 1 ? ', ' : '')
          } else {
            voucherSerialCodeList.push(data[i].voucher_list[j].serial)
          }
        }
        let voucherHistoryObj = {
          stt: handleExcel ? i + 1 : (i + 1 + (paginate.page - 1) * paginate.page_size),
          dateCreated: dateUtils.formatBeautyDate(data[i].date_created),
          voucherSerial: voucherSerialCodeList,
          fromEntity: {
            displayName: data[i].from_entity.name !== '' ? data[i].from_entity.name : ((data[i].from_user.last_name || data[i].from_user.first_name) ? functionUtils.concatFullname(data[i].from_user.last_name, data[i].from_user.first_name) : data[i].from_user.email),
            entity: {
              id: data[i].from_entity.id,
              name: data[i].from_entity.name
            },
            user: this.getDetailUserInfo(data[i].from_user)
          },
          toEntity: data[i].to_entity,
          customer: this.getDetailUserInfo(data[i].to_user),
          userPerform: this.getDetailUserInfo(data[i].user_created),
          action: handleExcel ? this.getActionName(data[i].type, handleExcel) : data[i].type,
          description: description,
          totalLengthVoucher: data[i].total_voucher
        }
        voucherHistoryList.push(voucherHistoryObj)
      }
      return voucherHistoryList
    },
    // Rename key
    renameKey: function (object, key, newKey) {
      const targetKey = object[key]
      delete object[key]
      object[newKey] = targetKey
      return object
    },
    /**
     * Export to excel file
     */
    onExportToExcelFile: function () {
      if (this.isEntityDisabled()) {
        return // Locked or expired entity will not able to perform these actions
      }
      let filter = {
        params: {
          startDate: this.range.start,
          endDate: this.range.end,
          voucherSerial: this.historySearch.voucherSerial,
          customerInfo: this.historySearch.customerInfo,
          agentName: this.historySearch.agentName
        }
      }
      this.isLoading = true
      this.GET_VOUCHER_HISTORY_LIST(filter).then(
        function (res) {
          let data = [...this.handleVoucherHistoriesData(res.data, true)]
          for (let i = 0, size = data.length; i < size; i++) {
            for (var property in data[i]) {
              if (property === 'dateCreated') {
                this.renameKey(data[i], property, this.$t('voucherHistory.dateAction'))
              }
              if (property === 'voucherSerial') {
                this.renameKey(data[i], property, this.$t('evoucher.voucherSerial'))
              }
              if (property === 'fromEntity') {
                this.renameKey(data[i], property, this.$t('voucherHistory.implementAgencies'))
              }
              if (property === 'toEntity') {
                this.renameKey(data[i], property, this.$t('voucherHistory.receivingAgencies'))
              }
              if (property === 'customer') {
                this.renameKey(data[i], property, this.$t('customer.customer'))
              }
              if (property === 'userPerform') {
                this.renameKey(data[i], property, this.$t('voucherHistory.peopleAction'))
              }
              if (property === 'action') {
                this.renameKey(data[i], property, this.$t('voucherHistory.action'))
              }
              if (property === 'description') {
                this.renameKey(data[i], property, this.$t('evoucher.issuance.description'))
              }
              if (property === 'stt') {
                this.renameKey(data[i], property, this.$t('common.nonumber'))
              }
            }
          }
          // export json to Worksheet of Excel
          // only array possible
          var voucherHistories = XLSX.utils.json_to_sheet(data)
          // var pokemonWS = XLSX.utils.json_to_sheet(this.Datas.pokemons)
          // A workbook is the name given to an Excel file
          var wb = XLSX.utils.book_new() // make Workbook of Excel
          // add Worksheet to Workbook
          // Workbook contains one or more worksheets
          let entityName = sessionStorage.getItem('entityName')
          XLSX.utils.book_append_sheet(wb, voucherHistories, `${this.$t('evoucher.history')}`) // sheetAName is name of Worksheet
          // export Excel file
          XLSX.writeFile(wb, `${this.$t('evoucher.history')} - ${entityName}` + '.xlsx') // name of the file is 'book.xlsx'
          this.isLoading = false
        }.bind(this)
      ).catch(
        function () {
          this.isLoading = false
        }.bind(this)
      )
    },
    getDetailUserInfo: function (user) {
      let userInfo = {
        fullname: functionUtils.concatFullname(user.last_name, user.first_name),
        phone: user.profile && user.phone,
        email: user.email,
        gender: user.profile && user.gender,
        birthDate: user.profile && user.profile.dob,
        id: user.id
      }
      return userInfo
    },
    /**
     * Get action name
     */
    getActionName: function (type, handleExcel) {
      return handleExcel ? this.$t(functionUtils.getHistoryActionName(type)) : functionUtils.getHistoryActionName(type)
    },
    /**
     * Page change
     */
    onPageChange: function (page) {
      this.paginate.currentPage = page
      this.getVoucherHistoryList()
    },
    /**
     * Get voucher history list
     */
    getVoucherHistoryList: function () {
      let filter = {
        params: {
          page: this.paginate.currentPage,
          startDate: this.range.start,
          endDate: this.range.end,
          voucherSerial: this.historySearch.voucherSerial,
          customerInfo: this.historySearch.customerInfo,
          agentName: this.historySearch.agentName
        }
      }
      this.isLoading = true
      this.GET_VOUCHER_HISTORY_LIST(filter).then(
        function (res) {
          let data = res.data
          // Handle paginate
          this.paginate.currentPage = data.page
          this.paginate.totalPage = data.num_pages
          this.paginate.rowPerPage = data.per_page / 2
          // Handle data
          let voucherHistories = this.handleVoucherHistoriesData(data.results, false, data)
          this.voucherHistoryList = voucherHistories
          this.isLoading = false
        }.bind(this)
      ).catch(
        function () {
          this.isLoading = false
        }.bind(this)
      )
    },
    viewDetailEntity: function (entity) {
      this.$refs.entityDetailModal.onShowModal(entity.id)
    },
    viewCustomerInfo: function (item) {
      this.$refs.updateCustomerInfo.onShowModal(item, true)
    },
    ...mapActions([
      'GET_VOUCHER_HISTORY_LIST'
    ])
  }
}
</script>

<style lang="scss" scoped>
.action-item {
  cursor: pointer !important;
  border-bottom: 1px dashed #5cb860;
}
</style>
