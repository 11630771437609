<template>
  <v-dialog
    v-model="isShowModal"
    max-width="800px">
    <v-card>
      <material-card
        :title="$t('user.detailInfo')"
        tile
        color="green"
        full-width
      >
        <v-container grid-list-xl>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation>
            <v-layout wrap>
              <v-flex
                xs12
                sm8
                md6>
                <v-text-field
                  v-model="email"
                  :label="$t('common.email')"
                  :name="$t('common.email')"
                  disabled
                  prepend-icon="mdi-email"
                  type="text"
                  class="required"
                />
              </v-flex>
              <v-flex
                xs12
                sm8
                md6>
                <v-text-field
                  :rules="[ruleRequiredValue, ruleValidFullname]"
                  v-model="fullName"
                  :label="$t('user.fullname')"
                  :name="$t('user.fullname')"
                  :disabled="isReadonly"
                  prepend-icon="mdi-account-group"
                  type="text"
                  class="required"
                />
              </v-flex>
              <v-flex
                xs12
                sm8
                md6>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="birtDateFormatted"
                      :label="$t('user.dob')"
                      :disabled="isReadonly"
                      persistent-hint
                      prepend-icon="mdi-cake"
                      @blur="date = parseDate(birtDateFormatted)"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-show="!isReadonly"
                    v-model="birtDate"
                    :disabled="isReadonly"
                    no-title
                    @input="menu = false"
                  />
                </v-menu>
              </v-flex>
              <v-flex
                xs12
                sm8
                md6>
                <v-text-field
                  :rules="[ruleValidPhoneNumber]"
                  v-model="phone"
                  :label="$t('user.phone')"
                  :name="$t('user.phone')"
                  :disabled="isReadonly"
                  prepend-icon="mdi-cellphone-iphone"
                />
              </v-flex>
              <v-flex
                xs12
                sm8
                md6>
                <v-select
                  v-model="gender"
                  :items="genderList"
                  :label="$t('user.gender')"
                  :disabled="isReadonly"
                  prepend-icon="mdi-gender-transgender"
                >
                  <template
                    slot="selection"
                    slot-scope="data">
                    <!-- HTML that describe how select should render selected items -->
                    {{ $t(data.item.text) }}
                  </template>
                  <template
                    slot="item"
                    slot-scope="data">
                    <!-- HTML that describe how select should render items when the select is open -->
                    {{ $t(data.item.text) }}
                  </template>
                </v-select>
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal"
        >{{ $t("common.close") }}
        </v-btn>
        <v-btn
          v-show="!isReadonly"
          :loading="isLoading"
          small
          color="success darken-1"
          @click="onConfirm"
        >{{ $t("common.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
  </v-dialog>
</template>
<script>
import functionUtils from 'utils/functionUtils'
import validationRules from 'utils/validationRules'
import stringUtils from 'utils/stringUtils'
import { mapActions, mapGetters } from 'vuex'
import ToastType from 'enum/toastType'
import UserType from 'enum/userType'
import dateUtils from 'utils/dateUtils'
export default {
  data () {
    return {
      valid: false,
      isReadonly: false,
      isShowModal: false,
      birtDate: null,
      birtDateFormatted: null,
      genderList: stringUtils.GenderList,
      username: null,
      password: null,
      birthDate: null,
      gender: null,
      fullName: null,
      phone: null,
      menu: false,
      currentPage: 1,
      email: null,
      userId: null,
      isLoading: false
    }
  },
  computed: {
    computedDateFormatted () {
      return this.formatDate(this.birtDate)
    },
    ...mapGetters([
      'GET_SNACK_BAR'
    ])
  },
  watch: {
    birtDate (val) {
      this.birtDateFormatted = this.formatDate(this.birtDate)
    }
  },
  methods: {
    /**
     * Required value
     */
    ruleRequiredValue (value) {
      return validationRules.ruleRequiredValue(value)
    },
    // ruleValidEmail (value) {
    //   return validationRules.ruleValidEmailFormat(value)
    // },
    ruleValidFullname (value) {
      return this.isReadonly || validationRules.ruleValidFullname(value)
    },
    ruleValidPhoneNumber (value) {
      return this.isReadonly || validationRules.ruleValidPhoneNumber(value)
    },
    isEntityDisabled: function () {
      return functionUtils.isCurrentEntityLocked() || functionUtils.isCurrentEntityExpired()
    },
    /**
     * Format date
     */
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    /**
     * Parse date
     */
    parseDate (date) {
      if (!date) return null
      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    /**
     * Check null
     */
    isNull: function (data) {
      return functionUtils.isNull(data)
    },
    /**
     * Show modal
     */
    onShowModal: function (customerInfo, disabled) {
      this.isShowModal = true
      this.isReadonly = disabled || (customerInfo.type !== UserType.GHOST) || this.isEntityDisabled()
      this.userId = customerInfo.id
      this.fullName = customerInfo.fullname
      this.gender = customerInfo.gender
      this.phone = customerInfo.phone
      this.birtDateFormatted = dateUtils.formatBeautyDate(customerInfo.birthDate, null)
      this.email = customerInfo.email
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.isShowModal = false
    },
    /**
     * Confirm
     */
    onConfirm: function () {
      if (this.isEntityDisabled()) {
        return // Locked or expired entity will not able to perform these actions
      }
      if (this.$refs.form.validate()) {
        if (!functionUtils.validateEmail(this.email)) {
          this.ON_SHOW_TOAST({
            message: this.$t('validation.invalidEmail'),
            styletype: ToastType.ERROR
          })
        } else {
          let firstLastName = functionUtils.getFirstLastName(this.fullName)
          let filter = {
            lastName: firstLastName.lastName,
            firstName: firstLastName.firstName,
            birthDate: this.birtDateFormatted,
            phone: this.phone,
            gender: this.gender,
            userId: this.userId
          }
          this.isLoading = true
          this.UPDATE_CUSTOMER_INFO(filter)
            .then(
              function () {
                this.isLoading = false
                this.fullName = null
                this.birthDate = null
                this.birtDateFormatted = null
                this.phone = null
                this.gender = null
                this.email = null
                this.userId = null
                this.ON_SHOW_TOAST({
                  message: this.$t('common.success'),
                  styleType: ToastType.SUCCESS
                })
                this.isShowModal = false
                this.$emit('updateCustomerInfoSuccess')
              }.bind(this)
            )
            .catch(
              function (error) {
                this.isLoading = false
                if (!functionUtils.isNull(error.response.data.errorText)) {
                  let errorText = error.response.data.errorText
                  this.ON_SHOW_TOAST({
                    message: this.$t(`${errorText}`),
                    styletype: ToastType.ERROR
                  })
                } else {
                  this.ON_SHOW_TOAST({
                    message: this.$t('login.somethingWentWrong'),
                    styletype: ToastType.ERROR
                  })
                }
              }.bind(this)
            )
        }
      }
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'UPDATE_CUSTOMER_INFO'
    ])
  }
}
</script>

<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
/deep/.required label::after {
  content: " *";
  color: red;
}
</style>
